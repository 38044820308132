import React from 'react'

import { Link } from 'gatsby'

import * as THREE from 'three'
import WAVES from 'vanta/dist/vanta.waves.min'

import ProfileImage from '../presentationals/ProfileImage'
import Chevron from '../presentationals/svg/Chevron'

import { routes } from '../../data'

export default class FullpageProfile extends React.Component {
  constructor() {
    super()
    this.vantaRef = React.createRef()
  }
  componentDidMount() {
    this.vantaEffect = WAVES({
      el: this.vantaRef.current,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x2c2a41,
      shininess: 24.0,
      waveHeight: 12.0,
      waveSpeed: 0.6,
      zoom: 0.9,
      THREE: THREE,
    })
  }
  render() {
    return (
      <div
        ref={this.vantaRef}
        id="homepage-landing"
        className="fullpage-section"
      >
        <div className="container container--narrower position-relative">
          <div className="centered-parent">
            <div className="centered-child">
              <div className="profile-photo">
                <ProfileImage alt="profile-photo" />
              </div>
              <h2 className="profile-name color-4 font-1">JERONIMAS DARGIS</h2>
              <div
                className="profile-description font-2"
                style={{ color: '#fff' }}
              >
                London based developer. Currently working @{' '}
                <a
                  href="https://rcco.uk/"
                  target="_blank"
                  className="link_underline"
                  style={{ cursor: 'pointer', color: '#fff' }}
                >
                  RCCO.
                </a>{' '}
                Delivering user{' '}
                <span className="link link_underline" style={{ color: '#fff' }}>
                  interfaces
                </span>{' '}
                using latest tools and{' '}
                <span className="link link_underline" style={{ color: '#fff' }}>
                  technology
                </span>
                .
              </div>
              <Link to="#contact-form" className="profile-button button">
                Get in touch
              </Link>
            </div>
          </div>
          <div className="animated-bounce">
            <a href="#main-content" className="chevron-down position-absolute">
              <Chevron />
            </a>
          </div>
        </div>
      </div>
    )
  }
}
