import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import Switch from '@material-ui/core/Switch'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import Brightness3Icon from '@material-ui/icons/Brightness3'

export default function DayNightThemeSwitcher() {
  return (
    <div className="display-flex">
      <Brightness3Icon area-labeledby="night mode" />
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <Switch
            onChange={e => toggleTheme(e.target.checked ? 'light' : 'dark')}
            checked={theme === 'light'}
            color="primary"
            inputProps={{ 'aria-label': 'Toggle theme' }}
          />
        )}
      </ThemeToggler>
      <WbSunnyIcon area-labeledby="day mode" />
    </div>
  )
}
