import React from 'react'

import SEO from '../common/seo'

import { Layout } from '../components/containers/layout'
import FullpageProfile from '../components/containers/FullpageProfile'
// import { ProjectTileGrid } from '../components/containers/ProjectTileGrid'

import TitleWithCTA from '../components/presentationals/TitleWithCTA'
import Contact from '../components/containers/Contact'

import Fade from 'react-reveal/Fade'

import DayNightThemeSwitcher from '../components/presentationals/DayNightThemeSwitcher'

import { routes } from '../data'

const { projects, posts, clients } = routes

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      lastScrollPosition: 0,
      showNavbar: true,
    }
    this.scrollHandler = this.scrollHandler.bind(this)
  }

  scrollHandler(e) {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop
    if (currentScrollPosition < 0) {
      return
    }
    console.log(currentScrollPosition)
    this.setState({
      showNavbar: currentScrollPosition < this.state.lastScrollPosition,
    })
    this.setState({ lastScrollPosition: currentScrollPosition })
  }

  componentDidMount() {
    document.addEventListener('scroll', this.scrollHandler, { passive: true })
    this.setState({ loaded: true })
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler)
  }
  modeToggle = () => {
    if (this.state.loaded) {
      return (
        <div className="overlay__theme-toggler">
          <Fade top when={this.state.showNavbar}>
            <DayNightThemeSwitcher />
          </Fade>
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <FullpageProfile />
        <this.modeToggle />
        <div
          className="container"
          id="main-content"
          style={{ paddingTop: '3em' }}
        >
          {/* <TitleWithCTA title="Projects" />
          <ProjectTileGrid /> */}
          <br />
          <br />
          <div id="contact-form" />
          <TitleWithCTA title="Contact" />
          <Contact />
        </div>
      </Layout>
    )
  }
}

export default IndexPage
