import React from 'react'

import SEO from '../../common/seo'

import ContactForm from './ContactForm'

import Socials from '../presentationals/Socials.js'

const Contact = () => {
  return (
    <div className="section-spacing">
      <div className="container container--narrower">
        <p className="font-5">
          {/* eslint-disable jsx-a11y/accessible-emoji */}
          Ping me if you have any questions!
          <span role="img" area-labeledby="fire" style={{ marginLeft: 4 }}>
            🔥
          </span>
          <span role="img" area-labeledby="rocket">
            📫
          </span>
        </p>
        <ContactForm />
        <hr style={{ marginTop: 32 }} />
        <Socials showEmail={false} />
      </div>
    </div>
  )
}

export default Contact
