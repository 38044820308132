import React from 'react'

import { Link } from 'gatsby'

import ReCAPTCHA from 'react-google-recaptcha'
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core'

import { routes } from '../../data'

const { privacyPolicy } = routes

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: '',
    }
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: 'SUCCESS' })
      } else {
        this.setState({ status: 'ERROR' })
      }
    }
    xhr.send(data)
  }

  render() {
    const { status } = this.state
    return (
      <form
        action="https://formspree.io/xnqgerpo"
        method="POST"
        onSubmit={this.submitForm}
      >
        <TextField
          label="Email"
          type="email"
          name="email"
          variant="outlined"
          required={true}
        />
        <TextField
          label="Message"
          name="message"
          multiline
          rows="10"
          margin="normal"
          variant="outlined"
          required={true}
        />
        <FormControlLabel
          value="end"
          control={<Checkbox color="primary" />}
          label={
            <p className="font-5">
              I have read and agreed with
              <Link to={privacyPolicy} style={{ marginLeft: 4 }}>
                Privacy Policy
              </Link>{' '}
              of using this site.
            </p>
          }
          labelPlacement="end"
        />
        <div className="contact-form__bottom">
          <div className="contact-form__bottom__item">
            <ReCAPTCHA sitekey="6Lcfn8oZAAAAAHCsBzIRdActikLdvlSLh6UOJBOn" />
          </div>
          <div className="contact-form__bottom__item">
            <button className="button">Submit</button>
          </div>
        </div>
        {status === 'SUCCESS' && (
          <p
            className="font-5"
            style={{ padding: 20, background: 'green', color: 'white' }}
          >
            Thanks for getting in touch!
          </p>
        )}
        {status === 'ERROR' && (
          <p
            className="font-5"
            style={{ padding: 20, background: 'red', color: 'white' }}
          >
            Ooops! There was an error.
          </p>
        )}
      </form>
    )
  }
}
